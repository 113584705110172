import { useMemo, useState } from 'react';

import {
  Box,
  Paper,
  useTheme,
  useMediaQuery,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Icon,
  Modal,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import { useStore } from '../../contexts/StoreContext';
import { useAuth } from '../../contexts/AuthContext';

import { IStores } from '../../Dtos/IAuthLogin';

interface OptionsProps {
  value: string;
  label: string;
}
interface FilterProps {
  showFilter?: boolean;
  filterOptions?: OptionsProps[];
  handleFilter?: (option: number) => void;
}

export const DashboardToolbar: React.FC<FilterProps> = ({
  showFilter = false,
  handleFilter,
}) => {
  const theme = useTheme();
  const { payload } = useAuth();

  const [openModal, setOpenModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');

  const modalStyles = {
    position: 'absolute',
    /* top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', */
    right: '0',
    top: '16%',
    width: '84%',
    margin: '0 4px',
    bgcolor: 'background.paper',
    border: '2px solid #ffffff',
    borderRadius: '4px',
    boxShadow: 24,
    p: 3,
  };

  const { changeStore, store } = useStore();

  const stores = useMemo(() => {
    if (payload?.user.stores.length) return payload?.user.stores;

    return [];
  }, []);

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const selectStyle = {
    width: smDown ? '100%' : mdDown ? '50%' : '40%',
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilter((event.target as HTMLInputElement).value);

    if (handleFilter) handleFilter(Number(event.target.value));
    setOpenModal(false);
  };

  return (
    <Box
      gap={1}
      marginX={0.5}
      padding={0.5}
      display="flex"
      alignItems="center"
      component={Paper}
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <FormControl fullWidth>
          <InputLabel id="store-select">Loja</InputLabel>
          <Select
            style={selectStyle}
            labelId="store-select"
            value={store?.toString() || ''}
            label="Loja"
            onChange={changeStore}
          >
            {stores.length > 0
              ? stores?.map((store: IStores) => (
                  <MenuItem key={store.id} value={store.id}>
                    {store.name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>

        {showFilter && (
          <>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
              <Box sx={modalStyles}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Status do pedido
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={handleRadioChange}
                    value={selectedFilter}
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Todos"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Em fila de separação"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Em separação"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="Separação Pausada"
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio />}
                      label="Separação Concluída"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Modal>
            <Button onClick={() => setOpenModal(true)}>
              <Icon>filter_alt</Icon>
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
