import { api } from '../shared/services/api';

const getOrdersByStore = async (store: number) => {
  try {
    const response = await api.get(`/orders?store=${store}`);
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

const getOrderById = async (orderId: string) => {
  try {
    const response = await api.get(`/orders/${orderId}`);
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

interface IUpdateOrderStatusProps {
  orderId: number;
  status: number;
  sendPDV: boolean;
}

interface IUpdateOrderFullProps {
  orderId: number;
  command: string;
}

const updateOrderStatus = async ({
  orderId,
  status,
  sendPDV,
}: IUpdateOrderStatusProps) => {
  try {
    const response = await api.put(`/orders/${orderId}`, {
      status,
      sendPDV,
    });
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

const updateOrderFull = async ({ orderId, command }: IUpdateOrderFullProps) => {
  try {
    const response = await api.put(`/orders/update_full/${orderId}`, {
      command,
    });
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

interface IUpdateOrderQuantityProps {
  orderId: number;
  itemId: number;
  collected?: number;
  notFound?: boolean;
}

const updateProductQuantity = async ({
  orderId,
  itemId,
  collected,
  notFound = false,
}: IUpdateOrderQuantityProps) => {
  try {
    const response = await api.put(`/orders/${orderId}/products`, {
      id: itemId,
      collected,
      not_found: notFound,
    });
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

const getHallwayByStore = async (storeId: number) => {
  try {
    const response = await api.get(`/stores/${storeId}/locations`);

    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
};

interface IProductProps {
  productId: string;
  storeId: number;
  locationHallwayId: number;
  locationShelfId: number;
}

const updateProductLocation = async (payload: IProductProps) => {
  try {
    const response = await api.post('/products/location', {
      product_id: payload.productId,
      store_id: payload.storeId,
      location_hallway_id: payload.locationHallwayId,
      location_shelf_id: payload.locationShelfId,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
};

const getDefaultConfig = async () => {
  try {
    const response = await api.get(`/configuration/default_config/copercana`);
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

export {
  getOrdersByStore,
  getOrderById,
  updateOrderStatus,
  updateOrderFull,
  updateProductQuantity,
  getHallwayByStore,
  updateProductLocation,
  getDefaultConfig,
};
