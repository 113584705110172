import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { BaseLayoutPage } from '../../shared/layouts';
import { DashboardToolbar } from '../../shared/components';
import { getOrdersByStore } from '../../api/pickingAppApi';

import { useAuth } from '../../shared/contexts/AuthContext';
import { useStore } from '../../shared/contexts/StoreContext';
import { useToast } from '../../shared/contexts/ToastContext';
import { IOrdersDto } from '../../shared/Dtos/IOrdersDto';

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  const { payload } = useAuth();
  const { store } = useStore();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(0);
  const [collecting, setCollecting] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [paused, setPaused] = useState(0);
  const [processing, setProcessing] = useState(0);
  const [error, setError] = useState(0);
  const [cancel, setCancel] = useState(0);

  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const cardStyle = {
    width: smDown ? '95px' : mdDown ? '195px' : '325px',
    height: smDown ? '105px' : mdDown ? '195px' : '325px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-evenly',
  };

  const typographyStyle = {
    fontSize: smDown ? '12.5px' : mdDown ? '18.5px' : '26.5px',
  };

  const typographyStyleNumber = {
    fontSize: smDown ? '24px' : mdDown ? '40px' : '60px',
    padding: smDown ? '0px' : mdDown ? '35px' : '70px',
  };

  const getOrders = useCallback(async () => {
    try {
      if (store) {
        setLoading(true);
        const { data } = await getOrdersByStore(store);

        const pendingOrders = data.data.list.filter(
          (item: IOrdersDto) => item.status === 1,
        );

        setPending(pendingOrders.length);

        const separatingOrders = data.data.list.filter(
          (item: IOrdersDto) => item.status === 2,
        );

        setCollecting(separatingOrders.length);

        const completedOrders = data.data.list.filter(
          (item: IOrdersDto) => item.status === 4,
        );

        setCompleted(completedOrders.length);

        const pausedOrders = data.data.list.filter(
          (item: IOrdersDto) => item.status === 3,
        );

        setPaused(pausedOrders.length);

        const errorOrders = data.data.list.filter(
          (item: IOrdersDto) => item.status === 10,
        );
        setError(errorOrders.length);

        const processingOrders = data.data.list.filter(
          (item: IOrdersDto) => item.status === 5,
        );
        setProcessing(processingOrders.length);

        const cancelOrders = data.data.list.filter(
          (item: IOrdersDto) => item.status === 9,
        );
        setCancel(cancelOrders.length);
      }
    } catch (err) {
      setPending(0);
      setCollecting(0);
      setCompleted(0);
      setPaused(0);

      addToast({
        type: 'error',
        title: 'Erro na Request',
        description: 'Entrar em contato com o suporte',
      });
    } finally {
      setLoading(false);
    }
  }, [store]);

  useEffect(() => {
    if (store) getOrders();
  }, [store]);

  return (
    <BaseLayoutPage title="Home" toolbar={<DashboardToolbar />}>
      <CardContent>
        <Typography marginTop={2} variant="h5" align="left">
          Bem vindo, {payload?.user.name}!
        </Typography>
      </CardContent>
      <Box display="flex" alignItems="center">
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex={1}
            marginTop={30}
          >
            <CircularProgress color="primary" size={80} />
          </Box>
        ) : (
          <Grid container margin={2}>
            <Grid
              item
              container
              spacing={2}
              flex="1"
              justifyContent="space-evenly"
            >
              <Grid item flex="1">
                <Card
                  style={cardStyle}
                  onClick={() =>
                    navigate({
                      pathname: '/orders',
                      search: '?status=1',
                    })
                  }
                >
                  <CardContent>
                    <Typography
                      variant="h1"
                      align="center"
                      style={typographyStyle}
                    >
                      Em fila de separação
                    </Typography>

                    <Box
                      padding={3}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h2"
                        color="secondary"
                        align="center"
                        style={typographyStyleNumber}
                      >
                        {pending || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item flex="1">
                <Card
                  style={cardStyle}
                  onClick={() =>
                    navigate({
                      pathname: '/orders',
                      search: '?status=2',
                    })
                  }
                >
                  <CardContent>
                    <Typography
                      variant="h1"
                      align="center"
                      style={typographyStyle}
                    >
                      Em separação
                    </Typography>

                    <Box
                      padding={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h2"
                        color="secondary"
                        align="center"
                        style={typographyStyleNumber}
                      >
                        {collecting || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item flex="1">
                <Card
                  style={cardStyle}
                  onClick={() =>
                    navigate({
                      pathname: '/orders',
                      search: '?status=3',
                    })
                  }
                >
                  <CardContent>
                    <Typography
                      variant="h1"
                      align="center"
                      style={typographyStyle}
                    >
                      Separação Pausada
                    </Typography>

                    <Box
                      padding={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h2"
                        color="secondary"
                        style={typographyStyleNumber}
                      >
                        {paused || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item flex="1">
                <Card
                  style={cardStyle}
                  onClick={() =>
                    navigate({
                      pathname: '/orders',
                      search: '?status=4',
                    })
                  }
                >
                  <CardContent>
                    <Typography
                      variant="h1"
                      align="center"
                      style={typographyStyle}
                    >
                      Separação Concluída
                    </Typography>

                    <Box
                      padding={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h2"
                        color="secondary"
                        style={typographyStyleNumber}
                      >
                        {completed || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item flex="1">
                <Card
                  style={cardStyle}
                  onClick={() =>
                    navigate({
                      pathname: '/orders',
                      search: '?status=5',
                    })
                  }
                >
                  <CardContent>
                    <Typography
                      variant="h1"
                      align="center"
                      style={typographyStyle}
                    >
                      Em processamento
                    </Typography>

                    <Box
                      padding={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h2"
                        color="secondary"
                        style={typographyStyleNumber}
                      >
                        {processing || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item flex="1">
                <Card
                  style={cardStyle}
                  onClick={() =>
                    navigate({
                      pathname: '/orders',
                      search: '?status=10',
                    })
                  }
                >
                  <CardContent>
                    <Typography
                      variant="h1"
                      align="center"
                      style={typographyStyle}
                    >
                      Falha na Integração
                    </Typography>

                    <Box
                      padding={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h2"
                        color="secondary"
                        style={typographyStyleNumber}
                      >
                        {error || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item flex="1">
                <Card
                  style={cardStyle}
                  onClick={() =>
                    navigate({
                      pathname: '/orders',
                      search: '?status=9',
                    })
                  }
                >
                  <CardContent>
                    <Typography
                      variant="h1"
                      align="center"
                      style={typographyStyle}
                    >
                      Pedidos Cancelados
                    </Typography>

                    <Box
                      padding={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="h2"
                        color="secondary"
                        style={typographyStyleNumber}
                      >
                        {cancel || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </BaseLayoutPage>
  );
};
