import { createContext, useContext, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';
import { environment } from '../environment';

interface IStoreProviderProps {
  children: React.ReactNode;
}

interface IStoreContext {
  changeStore: (event: SelectChangeEvent) => void;
  store?: number;
}

const StoreContext = createContext({} as IStoreContext);

const StoreProvider: React.FC<IStoreProviderProps> = ({ children }) => {
  const [store, setStore] = useState(() => {
    const pickingApp = localStorage.getItem(environment.APP_NAME);

    if (pickingApp) {
      const app = JSON.parse(pickingApp);

      if (app?.store) {
        return app.store;
      }
    }

    return undefined;
  });

  const handleChangeStore = (event: SelectChangeEvent) => {
    const pickingApp = localStorage.getItem(environment.APP_NAME);

    if (pickingApp) {
      setStore(Number(event.target.value));

      const app = JSON.parse(pickingApp);

      Object.assign(app, {
        store: event.target.value,
      });

      localStorage.setItem(environment.APP_NAME, JSON.stringify(app));
    }
  };

  return (
    <StoreContext.Provider
      value={{
        changeStore: handleChangeStore,
        store: store,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

function useStore() {
  const context = useContext(StoreContext);

  if (!context) {
    throw new Error('useStore must be used within StoreProvider');
  }

  return context;
}

export { StoreProvider, useStore };
