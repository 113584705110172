import { AxiosRequestConfig } from 'axios';

import { environment } from '../../../../environment';

export const tokenInterceptor = (request: AxiosRequestConfig) => {
  const pickingApp = localStorage.getItem(environment.APP_NAME);

  if (pickingApp && request.headers) {
    const app = JSON.parse(pickingApp);
    request.headers.Authorization = `Bearer ${app.token.accessToken}`;
  }
  return request;
};
