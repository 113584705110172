import { IOrdersDto } from '../Dtos/IOrdersDto';

interface StatusProps {
  [key: number]: string;
}

interface StatusGeneralProps {
  [key: string]: {
    [key: string]: string;
  };
}

export default function orderStatusEnum(order: IOrdersDto): string {
  const { status, status_general, payment_method } = order;
  const statusEnum: StatusProps = {
    1: 'Em fila de separação',
    2: 'Em separação',
    3: 'Separação Pausada',
    4: 'Pedido Finalizado',
    5: 'Em processamento',
    7: 'Separação Concluída',
    6: 'Integrado',
    8: 'Faturado',
    9: 'Cancelado',
    10: 'Falha na Integração',
  };

  const statusGeneralEnum: StatusGeneralProps = {
    BML: {
      WAITING_PAYMENT: 'Aguardando pagamento pela Operadora do cartão',
      PAYMENT_APPROVED: 'Pagamento aprovado',
      PAYMENT_REJECTED: 'Pagamento rejeitado',
      WAITING_PDV: 'Aguardando envio do PDV',
      WAITING_UPDATE_SALESFORCE: 'Aguardando status Salesforce',
      FAIL_INTEGRATION: 'Falha na Integração',
      FAIL_INTEGRATION_PDV: 'Falha na Integração - Erro no PDV',
      FAIL_INTEGRATION_SALESFORCE: 'Falha na Integração - Erro na Salesforce',
    },
    CREDIT_CARD: {
      WAITING_PAYMENT: 'Aguardando pagamento pela Operadora do cartão',
      PAYMENT_APPROVED: 'Pagamento aprovado',
      PAYMENT_REJECTED: 'Pagamento nao efetuado',
      WAITING_PDV: 'Pagamento aprovado - Aguardando envio do PDV',
      WAITING_UPDATE_SALESFORCE:
        'Pagamento aprovado - Aguardando status Salesforce',
      FAIL_INTEGRATION: 'Falha na Integração',
      FAIL_INTEGRATION_PAYMENT: 'Falha na Integração - Erro no pagamento',
      FAIL_INTEGRATION_PDV: 'Falha na Integração - Erro no PDV',
      FAIL_INTEGRATION_SALESFORCE: 'Falha na Integração - Erro na Salesforce',
    },
  };

  if (!status) return '';

  if (status == 4) {
    if (status_general == 'PAYMENT_REJECTED') return statusEnum[status];
  }

  if (status != 5 && status != 10 && status != 2) return statusEnum[status];

  if (!status_general) return statusEnum[status];

  if (status == 10)
    return `${statusGeneralEnum[payment_method][status_general]}`;

  return `${statusEnum[status]} - ${statusGeneralEnum[payment_method][status_general]}`;
}
