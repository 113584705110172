import { BrowserRouter } from 'react-router-dom';

import './assets/fonts/global.css';
import './assets/fonts/fonts.css';
import 'react-toastify/dist/ReactToastify.css';

import { AppProvider } from './shared/contexts';

import { AppRoutes } from './routes';

export const App: React.FC = () => (
  <AppProvider>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </AppProvider>
);
