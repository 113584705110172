import { useState, useEffect } from 'react';

import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { BaseLayoutPage } from '../../shared/layouts';
import { ListToolBar } from '../../shared/components';

interface IProductsProps {
  id: number;
  description: string;
  qtdStock: number;
}

const mockProducts: IProductsProps[] = [
  {
    id: 1,
    description: 'Produto 1',
    qtdStock: 50,
  },
  {
    id: 2,
    description: 'Produto 2',
    qtdStock: 10,
  },
  {
    id: 3,
    description: 'Produto 3',
    qtdStock: 60,
  },
  {
    id: 4,
    description: 'Produto 4',
    qtdStock: 50,
  },
  {
    id: 5,
    description: 'Produto 5',
    qtdStock: 30,
  },
  {
    id: 6,
    description: 'Produto 6',
    qtdStock: 530,
  },
  {
    id: 7,
    description: 'Produto 7',
    qtdStock: 90,
  },
  {
    id: 8,
    description: 'Produto 8',
    qtdStock: 25,
  },
  {
    id: 9,
    description: 'Produto 9',
    qtdStock: 50,
  },
  {
    id: 10,
    description: 'Produto 10',
    qtdStock: 50,
  },
];

export const Products: React.FC = () => {
  const [rows, setRows] = useState<IProductsProps[]>([]);
  const [searchField, setSearchField] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setRows(mockProducts);
      setIsLoading(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <BaseLayoutPage
      title="Produtos"
      toolbar={
        <ListToolBar
          showTextField
          searchTextField={searchField}
          onChangeSearchField={setSearchField}
          showSyncButton
        />
      }
    >
      <TableContainer
        sx={{ width: 'auto', m: 1 }}
        component={Paper}
        variant="outlined"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Qtde. Estoque</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell># {row.id}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.qtdStock}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={4}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </BaseLayoutPage>
  );
};
