import { createTheme } from '@mui/material';
import { ptBR } from '@mui/material/locale';

export const LightTheme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#018136',
        dark: '#018136',
        light: '#018136',
        contrastText: '#fff',
      },
      secondary: {
        main: '#E30613',
        dark: '#E30613',
        light: '#E30613',
        contrastText: '#fff',
      },
      background: {
        paper: '#fff',
        default: '#D4E4DD',
      },
    },
    typography: {
      allVariants: {
        color: 'black',
      },
    },
  },
  ptBR,
);
