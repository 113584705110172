import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import { getConfig } from '../../../config';

import {
  Box,
  Divider,
  useTheme,
  useMediaQuery,
  Typography,
  Card,
  Avatar,
  Icon,
  LinearProgress,
  IconButton,
  Modal,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { useParams } from 'react-router-dom';
import { BaseLayoutPage } from '../../../shared/layouts';

import { PickingToolbar } from '../../../shared/components';
import orderStatusEnum from '../../../shared/utils/orderStatusEnum';
import {} from '../../../shared/components/';

import { IOrdersDto, IProducts } from '../../../shared/Dtos/IOrdersDto';
import {
  getOrderById,
  updateOrderStatus,
  updateOrderFull,
} from '../../../api/pickingAppApi';

export interface IPicking {
  order: IOrdersDto;
  product: IProducts;
}

const styleBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '220px',
  bgcolor: 'background.paper',
  border: '2px solid #ffffff',
  borderRadius: '4px',
  boxShadow: 24,
  p: 3,
};

const styleBoxOrderConfirm = {
  position: 'absolute',
  top: '39%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #ffffff',
  borderRadius: '4px',
  boxShadow: 24,
  p: 3,
  fontSize: '0.75rem',
};

const styleBoxListProducts = {
  // height: '423px',
  maxHeight: '423px',
  overflow: 'overlay',
  backgroundImage:
    'linear-gradient(to top, white, white), linear-gradient(to top, white, white), linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)), linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0))',
  backgroundPosition: 'bottom center, top center, bottom center, top center',
  backgroundColor: '#FFF',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 20px, 100% 20px, 100% 10px, 100% 10px',
  backgroundAttachment: 'local, local, scroll, scroll',
};

interface IOrderProducts {
  hallway: string;
  list: IProducts[];
}

interface ICustomerProps {
  id: string;
  image: string;
  name: string;
  phone: string;
}

export const OrdersDetails: React.FC = () => {
  const navigate = useNavigate();

  const handleBackButton = useCallback(() => {
    navigate(`/orders`);
  }, []);

  const { id } = useParams<'id'>();
  const [order, setOrder] = useState<IOrdersDto>({} as IOrdersDto);

  const [statusOrder, setStatusOrder] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isPicking, setIsPicking] = useState(false);
  const [text, setText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalOrderCompare, setOpenModalOrderCompare] = useState(false);
  const [openPdvModal, setOpenPdvModal] = useState(false);
  const [showDevActions, setShowDevActions] = useState(false);
  const [products, setProducts] = useState<IOrderProducts[]>([]);
  const [customer, setCustomer] = useState<ICustomerProps>(
    {} as ICustomerProps,
  );

  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const imgStyle = {
    width: smDown ? '100px' : mdDown ? '115px' : '135px',
    height: smDown ? '100px' : mdDown ? '115px' : '135px',
  };

  const actionTextSize = {
    fontSize: '12px',
  };

  const handlePicking = useCallback(async () => {
    if (order.status == 5) {
      // send PDV to order with payment reject
      setOpenPdvModal(true);
    } else {
      // finish Order
      setIsButtonLoading(true);

      const params = {
        orderId: Number(id),
        status: 2,
        sendPDV: false,
      };
      const { data } = await updateOrderStatus(params);

      const statusOrder = orderStatusEnum(data.data);
      if (data.data.status === 2) setIsPicking(true);

      setOrder(data.data);
      setStatusOrder(statusOrder);
      setIsPicking(true);
      setIsButtonLoading(false);
    }
  }, [order]);

  const handleSavePicking = useCallback(async () => {
    const { product_items } = order;

    let totalQuantity = 0;
    let totalCollected = 0;
    product_items.forEach(product => {
      if (product.isProductWeighable && product.unit?.toLowerCase() === 'un') {
        totalQuantity += product.quantity;
        totalCollected += Number(product.collected);
      } else {
        totalQuantity += 1;

        if (Number(product.collected) !== 0) totalCollected += 1;
      }
    });

    if (totalQuantity !== totalCollected) {
      setText('Quantidade coletada é diferente da quantidade do pedido !');
    }

    setOpenModal(true);
  }, [order]);

  const finalizeOrder = async () => {
    const config = await getConfig();
    if (config.show_confirm_order_detail) {
      setOpenModal(false);
      setOpenModalOrderCompare(true);
    } else {
      // savePicking(4);
    }
  };

  const devButtonBypassPDV = async () => {
    await updateOrderFull({
      orderId: order.id,
      command: 'bypass_pdv',
    });
    getOrder();
  };

  const savePicking = useCallback(async (status: number) => {
    setIsPicking(false);
    setOpenModalOrderCompare(false);
    setIsButtonLoading(true);
    const { data } = await updateOrderStatus({
      orderId: Number(id),
      status,
      sendPDV: false,
    });

    const statusOrder = orderStatusEnum(data.data);
    if (data.data.status === 2) setIsPicking(true);

    setOrder(data.data);
    setStatusOrder(statusOrder);
    setIsButtonLoading(false);
    setOpenModal(false);
  }, []);

  const sendPDV = useCallback(async () => {
    setOpenPdvModal(false);
    setIsPicking(false);
    setIsButtonLoading(true);
    const { data } = await updateOrderStatus({
      orderId: Number(id),
      status: 4,
      sendPDV: true,
    });

    const statusOrder = orderStatusEnum(data.data);
    if (data.data.status === 2) setIsPicking(true);

    setOrder(data.data);
    setStatusOrder(statusOrder);
    setIsButtonLoading(false);
  }, []);

  const handlePickingProduct = useCallback(
    async (productData: IProducts) => {
      const data = {
        product: productData,
        order,
      };
      navigate(`/details/picking`, { state: data });
    },
    [navigate, order],
  );

  const getOrder = useCallback(async () => {
    setIsLoading(true);
    setIsButtonLoading(true);

    const { data } = await getOrderById(id as string);

    const { product_items } = data.data;

    setCustomer(data.data.customer_info);

    console.log(data.data);

    const productsGrouped = _.chain(product_items)
      .groupBy('location_hallway_name')
      .map((value: string, key: string) => ({
        hallway: key ? key : 'Corredor Não Cadastrado',
        list: value,
      }))
      .value() as unknown as IOrderProducts[];
    if (productsGrouped.length > 0) setProducts(productsGrouped);

    const status = orderStatusEnum(data.data);

    if (data.data.status === 2) setIsPicking(true);

    setOrder(data.data);
    setStatusOrder(status);
    setIsLoading(false);
    setIsButtonLoading(false);
  }, [order]);

  const listProducts = useCallback(
    (item: IOrderProducts) => {
      return (
        <div key={Math.random()}>
          <Typography variant="subtitle1">{item.hallway}</Typography>

          {item.list.map(product => (
            <Box marginBottom={2} marginTop={2} key={Math.random()}>
              <Card
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding={1}
                  width="100%"
                >
                  <Box key={Math.random()} style={imgStyle} marginRight={2}>
                    <img
                      style={imgStyle}
                      src={
                        product?.images.find(it => it.type === 'small')?.url
                          ? product?.images.find(it => it.type === 'small')?.url
                          : `https://ui-avatars.com/api/?background=fff&color=333&name=${product.product_name}`
                      }
                      alt={product.product_name}
                      loading="lazy"
                    />
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="column"
                    width="100%"
                    paddingRight={2}
                  >
                    <Typography variant="caption">
                      {product.product_name}
                    </Typography>
                    <Typography variant="subtitle2" color="#949393">
                      Código: {product.product_external_id}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      marginTop={1}
                    >
                      <Typography variant="subtitle2" color="tertiary">
                        Qtde: {product.quantity}
                      </Typography>

                      <Typography variant="subtitle2" color="primary">
                        Coletado: {product.collected}
                      </Typography>
                    </Box>
                    {product.not_found && (
                      <Typography variant="subtitle2" color="error">
                        Não encontrado: {product.quantity - product.collected}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {isPicking && (
                  <IconButton
                    size="small"
                    color="primary"
                    style={{ marginRight: '8px', height: '100%' }}
                    onClick={() => handlePickingProduct(product)}
                  >
                    <Icon>arrow_forward_ios</Icon>
                  </IconButton>
                )}
              </Card>
            </Box>
          ))}
        </div>
      );
    },
    [isPicking],
  );

  const listProductsCompact = useCallback(
    (items: any) => {
      const products: [any?] = [];
      items.forEach((item: any) => {
        item.list.forEach((product: any) => {
          let unit = product.productUnitMeasure || 'un';
          let quantityShow = product.quantity;
          let collected = product.collected;
          unit = unit.toLowerCase();
          let quantityUnit = '';
          if (unit == 'kg' && product.productUnitMeasure != product.unit) {
            quantityShow = product.averageWeight * product.quantity;
            quantityUnit = `(${product.quantity} ${product.unit})`;
            // quantityShow = product.quantity;
          } else {
            if (unit == 'kg') {
              quantityShow = quantityShow.toFixed(3);
              if (product.collected)
                collected = parseFloat(product.collected).toFixed(3);
            }
          }
          // if
          products.push({
            ...product,
            ...{
              unitShow: unit,
              quantityShow,
              quantityUnit,
              collected,
            },
          });
        });
      });
      return (
        <TableContainer
          sx={{ width: 'auto', m: 0 }}
          component={Paper}
          variant="outlined"
          style={{ backgroundColor: 'transparent' }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size="small">Descrição</TableCell>
                <TableCell size="small">Solicitado</TableCell>
                <TableCell size="small">Coletado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map(product => (
                <TableRow key={Math.random()}>
                  <TableCell size="small">{product.product_name}</TableCell>
                  <TableCell size="small">{`${product.quantityShow} ${product.unitShow} ${product.quantityUnit}`}</TableCell>
                  <TableCell size="small">
                    {`${product.collected} ${product.unitShow}`}
                    {product.not_found && (
                      <Typography variant="subtitle2" color="error">
                        Não encontrado: {product.quantity - product.collected}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    },
    [isPicking, order],
  );

  useEffect(() => {
    getOrder();
    loadConfig();
  }, []);

  const showDevActionDPV = () => {
    return order.status_pdv > 0;
  };

  const loadConfig = async () => {
    const config = await getConfig();
    if (config.show_order_bypass_status) {
      setShowDevActions(true);
    }
  };

  const getPaymentOption = (order: IOrdersDto) => {
    if (order.payment_option.toLocaleUpperCase() == 'CASH') {
      if (order.cash_change != '') {
        return `Dinheiro (Troco para ${order.cash_change})`;
      } else {
        return 'Dinheiro';
      }
    }
    return order.payment_option.toLocaleUpperCase();
  };

  const getPaymentMethod = (order: IOrdersDto) => {
    if (order.payment_method == 'BML') return 'Pagamento na Entrega';
    if (order.payment_method == 'CREDIT_CARD') {
      if (order.status_payment_msg == 'PAYMENT_REJECTED') {
        return 'Pagamento com cartão de crédito na loja online - Rejeitado';
      }
      if (order.status_payment_msg == 'PAYMENT_APPROVED') {
        return 'Pagamento com cartão de crédito na loja online - Aprovado';
      }
      return 'Pagamento com cartão de crédito na loja online';
    }

    return order.payment_method;
  };

  const getPaymentStatus = (order: IOrdersDto) => {
    if (order.payment_method == 'CREDIT_CARD') {
      if (order.status_general == 'PAYMENT_REJECTED') {
        return (
          <span style={{ color: '#E30613' }}>Status: Pagamento rejeitado</span>
        );
      }
      if (order.status_general == 'PAYMENT_APPROVED') {
        return 'Status: Pagamento aprovado';
      }
      if (order.status_general == 'WAITING_PAYMENT') {
        return 'Status: Aguardando pagamento pela Operadora do cartão';
      }
    }
    return '';
  };

  return (
    <BaseLayoutPage
      title={
        isLoading
          ? 'Carregando dados do pedido'
          : `Pedido #${order.external_id}`
      }
      toolbar={
        <PickingToolbar
          onClickBack={handleBackButton}
          onPicking={order.status === 2 ? handleSavePicking : handlePicking}
          order={order}
          isLoading={isButtonLoading}
        />
      }
    >
      {isLoading ? (
        <Box width="100%">
          <LinearProgress color="primary" />
        </Box>
      ) : (
        <Box margin={2}>
          {openModal && (
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
              <Box sx={styleBox}>
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  color="error"
                >
                  {text}
                </Typography>
                <Typography
                  variant="subtitle2"
                  marginTop={2}
                  textAlign="center"
                >
                  Deseja Finalizar o pedido ou continuar depois ?
                </Typography>

                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={2}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => finalizeOrder()}
                  >
                    <Typography variant="button" color="white">
                      Finalizar
                    </Typography>
                  </Button>

                  <div style={{ margin: '0 4px' }} />

                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => savePicking(3)}
                  >
                    <Typography variant="button">Depois</Typography>
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}

          {openModalOrderCompare && (
            <Modal
              open={openModalOrderCompare}
              onClose={() => setOpenModalOrderCompare(false)}
            >
              <Box sx={styleBoxOrderConfirm}>
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  color="error"
                >
                  {text}
                </Typography>
                <Typography
                  variant="subtitle2"
                  marginTop={2}
                  textAlign="center"
                >
                  Compare os itens solicitados e os coletados para finalizar o
                  pedido.
                </Typography>

                <div style={styleBoxListProducts}>
                  {listProductsCompact(products)}
                </div>

                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={2}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => savePicking(4)}
                  >
                    <Typography variant="button" color="white">
                      Finalizar
                    </Typography>
                  </Button>

                  <div style={{ margin: '0 4px' }} />

                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => setOpenModalOrderCompare(false)}
                  >
                    <Typography variant="button">Voltar</Typography>
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}

          {openPdvModal && (
            <Modal open={openPdvModal} onClose={() => setOpenPdvModal(false)}>
              <Box sx={styleBox}>
                <Typography
                  variant="subtitle2"
                  marginTop={2}
                  textAlign="center"
                >
                  Pagamento não efetuado. Deseja seguir com o envio para o PDV ?
                </Typography>

                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={2}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => sendPDV()}
                  >
                    <Typography variant="button" color="white">
                      Enviar
                    </Typography>
                  </Button>

                  <div style={{ margin: '0 4px' }} />

                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => setOpenPdvModal(false)}
                  >
                    <Typography variant="button">Não</Typography>
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}

          <Card>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              padding={3}
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  alt={order?.customer_name}
                  src={order?.customer_info?.image}
                />

                <Box marginLeft={2}>
                  <Typography variant="subtitle1">Pedido de</Typography>
                  <Typography variant="subtitle2">
                    {order?.customer_name}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Icon style={{ fontSize: '16px' }}>phone_in_talk</Icon>
                    <Typography
                      variant="subtitle2"
                      fontSize="16px"
                      style={{ marginLeft: '8px' }}
                    >
                      {customer.phone}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle2">
                      CPF NF Paulista: {order?.copCPF_NFPaulista}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle2">
                      CPF Promoção Vigente: {order?.copCPF_Promo}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle2">
                      CPF cartão Copercana: {order?.copCPF_Card}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* <Box marginRight={2}>
                <Typography variant="subtitle1" fontSize="24px">
                  Pedido
                </Typography>
                <Typography variant="subtitle2" fontSize="24px">
                  #{order?.external_id}
                </Typography>
              </Box> */}
            </Box>

            <Box paddingLeft={3} display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                color="secondary"
                style={{ marginLeft: '8px' }}
              >
                {statusOrder}
              </Typography>
            </Box>

            {showDevActions && showDevActionDPV() && (
              <Box paddingLeft={3} display="flex" alignItems="center">
                <Button variant="outlined" onClick={() => devButtonBypassPDV()}>
                  <Typography variant="button" color="red">
                    DEV - Enviar PDV (bypass)
                  </Typography>
                </Button>
              </Box>
            )}

            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              padding={2}
            >
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                padding={1}
                borderRadius={1}
                border={1}
              >
                <Icon>receipt_long</Icon>
                <Typography
                  variant="subtitle1"
                  marginLeft={2}
                  style={actionTextSize}
                >
                  Produtos
                </Typography>
                <Typography
                  variant="subtitle2"
                  marginLeft={2}
                  style={actionTextSize}
                >
                  {order?.total_products} itens
                </Typography>
              </Box>
            </Box>
            {order.delivery_type && (
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                flexDirection="column"
                padding={2}
                margin={2}
              >
                <Box display="flex" alignItems="center">
                  {order.delivery_type === 'DELIVERY' ? (
                    <Icon color="secondary">delivery_dining</Icon>
                  ) : (
                    <Icon color="secondary">badge</Icon>
                  )}

                  <Typography
                    variant="button"
                    marginLeft={2}
                    style={{ fontSize: '16px' }}
                    color="secondary"
                  >
                    {order.delivery_type === 'DELIVERY'
                      ? 'ENTREGA AGENDADA'
                      : order.delivery_type === 'PICKUP'
                      ? 'RETIRADA NA LOJA'
                      : 'ENTREGA COMUM'}
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  fontSize="18px"
                >
                  Previsão:{' '}
                  {format(
                    parseISO(order.delivery_start || order.delivery_end),
                    'dd/MM/yyy HH:mm',
                  )}
                </Typography>

                <Box
                  marginTop={2}
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Icon color="primary">paid</Icon>
                    <Typography
                      variant="subtitle1"
                      style={{
                        marginLeft: '8px',
                      }}
                    >
                      {getPaymentMethod(order)}
                      <br />
                      <span style={{ fontSize: 14 }}>
                        {getPaymentStatus(order)}
                      </span>
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginLeft: '24px',
                    }}
                  >
                    {getPaymentOption(order)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Card>
          <Box marginTop={2} marginBottom={2}>
            {products.map((item: IOrderProducts) => listProducts(item))}
          </Box>
        </Box>
      )}
    </BaseLayoutPage>
  );
};
