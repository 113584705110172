import { createTheme } from '@mui/material';
import { ptBR } from '@mui/material/locale';
import { cyan } from '@mui/material/colors';

export const DarkTheme = createTheme(
  {
    palette: {
      mode: 'dark',
      primary: {
        main: '#FECA0B',
        dark: '#FECA0B',
        light: '#FECA0B',
        contrastText: '#fff',
      },
      secondary: {
        main: cyan[500],
        dark: cyan[400],
        light: cyan[300],
        contrastText: '#fff',
      },
      background: {
        paper: '#303134',
        default: '#202124',
      },
    },
    typography: {
      fontFamily: 'avenir-light',
      allVariants: {
        color: 'white',
      },
    },
  },
  ptBR,
);
