/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useCallback, useContext, useState } from 'react';

import { api } from '../services/api';

import { environment } from '../environment';
import IAuthLoginDto from '../Dtos/IAuthLogin';

interface IAuthContextData {
  logout: () => void;
  login: (email: string, password: string) => Promise<IAuthLoginDto | void>;
  payload: IAuthLoginDto | undefined;
}

const AuthContext = createContext({} as IAuthContextData);

interface IAuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [data, setData] = useState<IAuthLoginDto | undefined>(() => {
    const app = localStorage.getItem(environment.APP_NAME);
    if (app) {
      return JSON.parse(app);
    }

    return undefined;
  });

  const handleLogin = useCallback(async (email: string, password: string) => {
    try {
      const result = await api.post('/login', { email, password });
      const { data } = result.data;
      const { access_token, expires_in, payload } = data;

      const pickingApp = {
        user: payload,
        token: {
          accessToken: access_token,
          expiresIn: expires_in,
        },
      };

      setData(pickingApp);

      localStorage.setItem(environment.APP_NAME, JSON.stringify(pickingApp));

      return pickingApp;
    } catch (err: any) {
      throw new Error(err);
    }
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(environment.APP_NAME);

    setData(undefined);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login: handleLogin,
        logout: handleLogout,
        payload: data,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
