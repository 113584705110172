export const environment = {
  /**
   * Define a quantidade de linhas a ser exibido na listagem
   */
  LINE_LIMIT: 10,
  /**
   * Placehover dos campos de pesquisa
   */
  SEARCH_INPUT: 'Pesquisar...',
  /**
   * Mensagem da lista caso nao tenha registro
   */
  EMPTY_LIST: 'Nenhum registro encontrado',
  /**
   * URL de acesso a identity
   */
  BASE_URL: process.env.BASE_URL,
  /**
   * API URL
   * dev: http://185.202.239.132:3340/
   */
  API_URL: process.env.REACT_APP_API_URL,
  /**
   * APP Name
   */
  APP_NAME: '@picking-app',
};
