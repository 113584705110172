import React from 'react';
import { AuthProvider } from './AuthContext';
import { DrawerProvider } from './DrawerContext';
import { AppThemeProvider } from './ThemeContext';
import { ToastProvider } from './ToastContext';
import { StoreProvider } from './StoreContext';

type IProps = {
  children: React.ReactNode;
};

const AppProvider: React.FC<IProps> = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <StoreProvider>
        <AppThemeProvider>
          <DrawerProvider>{children}</DrawerProvider>
        </AppThemeProvider>
      </StoreProvider>
    </AuthProvider>
  </ToastProvider>
);

export { AppProvider };
