import { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from '@unform/core';

import { Prefix } from './styles';

type VTextFieldProps = TextFieldProps & {
  name: string;
  label?: string;
  prefix?: string;
};

export const VTextField: React.FC<VTextFieldProps> = ({
  name,
  label,
  prefix,
  ...rest
}) => {
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);

  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    });
  }, [registerField, fieldName, value]);

  return (
    <>
      {prefix && <Prefix>{prefix}</Prefix>}
      <TextField
        {...rest}
        fullWidth
        label={label || undefined}
        error={!!error}
        helperText={error}
        defaultValue={defaultValue}
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyDown={() => (error ? clearError() : undefined)}
        style={{ marginTop: 8 }}
      />
    </>
  );
};
