import { createContext, useCallback, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import {
  FiXCircle,
  FiCheckCircle,
  FiAlertTriangle,
  FiInfo,
} from 'react-icons/fi';

import { toast, ToastContainer } from 'react-toastify';

import { Container } from './styles';
import './styles.css';

interface IProps {
  children: React.ReactNode;
}

interface IMessage {
  type: 'success' | 'error' | 'warning' | 'info';
  title: string;
  description: string;
}

interface IToastContextProps {
  addToast(message: IMessage): void;
}

const ToastContext = createContext<IToastContextProps>(
  {} as IToastContextProps,
);

const ToastProvider: React.FC<IProps> = ({ children }) => {
  const addToast = useCallback((message: IMessage) => {
    const { type, title, description } = message;

    const Message = () => (
      <Container>
        <strong>{title}</strong>
        <span>{description}</span>
      </Container>
    );

    const icons = {
      error: <FiXCircle color="#e74c3c" />,
      success: <FiCheckCircle color="#30d64c" />,
      warning: <FiAlertTriangle color="#F2E205" />,
      info: <FiInfo color="#3172b7" />,
    };

    toast[type](<Message />, {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: uuid(),
      icon: icons[type],
    });
  }, []);

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <ToastContainer autoClose={3000} />
    </ToastContext.Provider>
  );
};

function useToast(): IToastContextProps {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
