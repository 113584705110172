/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Icon,
  Paper,
  Skeleton,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material';

import { IOrdersDto } from '../../Dtos/IOrdersDto';

interface IDetailsToolbarProps {
  showBackButton?: boolean;
  showBackButtonLoading?: boolean;
  onClickBack?: () => void;
  onPicking?: (param: any) => void;
  order: IOrdersDto;
  isLoading?: boolean;
}

interface StatusProps {
  [key: number]: string;
}

export const PickingToolbar: React.FC<IDetailsToolbarProps> = ({
  showBackButton = true,
  showBackButtonLoading = false,
  onClickBack,
  isLoading = false,
  onPicking,
  order,
}) => {
  const theme = useTheme();

  const PickinButton: React.FC = () => {
    const icon: StatusProps = {
      1: 'shopping_cart',
      2: 'save',
      3: 'production_quantity_limits',
    };

    const description: StatusProps = {
      1: 'Iniciar Picking',
      2: 'Salvar e Fechar',
      3: 'Retomar Picking',
      5: 'Enviar para o PDV?',
    };

    if (![1, 2, 3, 5].includes(order.status)) {
      return null;
    }

    const colorButton = [2, 5].includes(order.status) ? 'secondary' : 'primary';

    if (
      order.payment_method == 'CREDIT_CARD' &&
      order.status_general == 'WAITING_PAYMENT' &&
      order.status == 5
    ) {
      return null;
    } else {
      // return null;
    }

    return (
      <Button
        variant="contained"
        color={colorButton}
        disableElevation
        startIcon={<Icon>{icon[Number(order.status)]}</Icon>}
        onClick={onPicking}
      >
        <Typography
          variant="button"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          color="white"
        >
          {description[Number(order.status)]}
        </Typography>
      </Button>
    );
  };

  return (
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height={theme.spacing(5)}
      component={Paper}
    >
      {showBackButton && !showBackButtonLoading && order.status !== 2 && (
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          startIcon={<Icon>arrow_back</Icon>}
          onClick={onClickBack}
        >
          <Typography
            variant="button"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            Voltar
          </Typography>
        </Button>
      )}

      {showBackButtonLoading && <Skeleton width={110} height={60} />}

      {isLoading ? (
        <CircularProgress variant="indeterminate" color="secondary" />
      ) : order.status !== 4 ? (
        <PickinButton />
      ) : null}
    </Box>
  );
};
