import { getDefaultConfig } from '../api/pickingAppApi';

const localDefaultConfig = `{
    "show_confirm_order_detail": false
}`;

const getRemoteConfig = async () => {
  const configResult = await getDefaultConfig();
  const config = configResult.data.data.configuration;
  localStorage.setItem('default_config', JSON.stringify(config));
  const timestampExpire = new Date();
  timestampExpire.setHours(timestampExpire.getHours() + 1);
  localStorage.setItem('default_config_expire', timestampExpire.toISOString());
  return config;
};

const getConfig = async () => {
  if (localStorage.getItem('default_config_expire')) {
    const expireTimestamp = new Date(
      localStorage.getItem('default_config_expire') || 'now',
    );
    const now = new Date();
    if (expireTimestamp.getTime() < now.getTime()) {
      const config = await getRemoteConfig();
      return config;
    }
  }
  if (localStorage.getItem('default_config')) {
    const defaultConfig =
      localStorage.getItem('default_config') || localDefaultConfig;
    return JSON.parse(defaultConfig);
  } else {
    const config = await getRemoteConfig();
    return config;
  }
};

export { getConfig };
