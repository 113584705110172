/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Icon,
  CircularProgress,
} from '@mui/material';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { VTextField } from '../../shared/components/forms';

import { useAuth } from '../../shared/contexts/AuthContext';
import { useToast } from '../../shared/contexts/ToastContext';
import getValidationErrors from '../../shared/utils/getValidationErrors';

import logo from '../../assets/logo.png';

interface ILoginData {
  email: string;
  password: string;
}

export const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { login } = useAuth();
  const navigate = useNavigate();

  const { addToast } = useToast();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = useCallback(async (data: ILoginData) => {
    try {
      formRef.current?.setErrors({});
      setIsLoading(true);

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um email válido')
          .required('O email é obrigatório!'),
        password: Yup.string().required('A senha é obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await login(data.email, data.password);

      if (response) navigate('/home');
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao logar',
        description: 'Verifique suas credenciais',
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid container margin={2}>
        <Grid
          item
          container
          width="100%"
          spacing={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <Card>
              <Form ref={formRef} onSubmit={handleLogin}>
                <CardContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={1}
                  >
                    <img
                      src={logo}
                      alt="Avalie Fácil"
                      width={210}
                      style={{ marginBottom: 16 }}
                    />

                    <Typography variant="h6" align="center">
                      Entre com sua conta
                    </Typography>

                    <VTextField name="email" label="Email" type="email" />

                    <VTextField
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(prev => !prev)}
                            >
                              {showPassword ? (
                                <Icon>visibility</Icon>
                              ) : (
                                <Icon>visibility_off</Icon>
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </CardContent>
                <CardActions>
                  <Box width="100%">
                    <Box width="100%" display="flex" justifyContent="center">
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button fullWidth variant="contained" type="submit">
                          Entrar
                        </Button>
                      )}
                    </Box>
                  </Box>
                </CardActions>
              </Form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
