import {
  Box,
  Button,
  Icon,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { environment } from '../../environment';

interface IListToolBarProps {
  searchTextField?: string;
  showTextField?: boolean;
  onChangeSearchField?: (newText: string) => void;
  showSyncButton?: boolean;
  onClickSync?: () => void;

  textButton?: string;
  showButton?: boolean;
  onClickButton?: () => void;
  iconButton?: string;
}

export const ListToolBar: React.FC<IListToolBarProps> = ({
  searchTextField = '',
  onChangeSearchField,
  showTextField = false,
  textButton = 'Novo',
  showSyncButton = false,
  onClickSync,
  showButton = false,
  onClickButton,
  iconButton = 'add',
}) => {
  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
    >
      {showTextField && (
        <TextField
          size="small"
          placeholder={environment.SEARCH_INPUT}
          value={searchTextField}
          onChange={event => onChangeSearchField?.(event.target.value)}
        />
      )}

      <Box flex={1} display="flex" justifyContent="start">
        {showButton && (
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            endIcon={<Icon>{iconButton}</Icon>}
            onClick={onClickButton}
          >
            {textButton}
          </Button>
        )}
      </Box>

      <Box flex={1} display="flex" justifyContent="end">
        {showSyncButton && (
          <Button
            variant="contained"
            color="primary"
            disableElevation
            endIcon={<Icon>sync</Icon>}
            onClick={onClickSync}
          >
            {smDown && mdDown ? 'Sync' : 'Sincronizar'}
          </Button>
        )}
      </Box>
    </Box>
  );
};
