import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import {
  Card,
  Typography,
  Avatar,
  Box,
  LinearProgress,
  IconButton,
  Icon,
  Divider,
} from '@mui/material';

import { BaseLayoutPage } from '../../shared/layouts';
import { DashboardToolbar } from '../../shared/components';
import { getOrdersByStore } from '../../api/pickingAppApi';

import { useStore } from '../../shared/contexts/StoreContext';
import { useToast } from '../../shared/contexts/ToastContext';

import { IOrdersDto } from '../../shared/Dtos/IOrdersDto';
import orderStatusEnum from '../../shared/utils/orderStatusEnum';

interface IOrdersProps extends IOrdersDto {
  statusDesc: string;
  deliveryDate: string;
  sortDate: Date;
}

export const Orders: React.FC = () => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const { store } = useStore();
  const { addToast } = useToast();

  const [rows, setRows] = useState<IOrdersProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOrders = useCallback(
    async (option = 0) => {
      try {
        if (store) {
          setIsLoading(true);

          const { data } = await getOrdersByStore(store);

          const formatedPayload: IOrdersProps[] = data.data.list.map(
            (item: IOrdersDto) => {
              return {
                ...item,
                sortDate: new Date(item.delivery_start || item.delivery_end),
                deliveryDate: format(
                  new Date(item.delivery_start || item.delivery_end),
                  'dd/MM/yyy HH:mm',
                ),
                statusDesc: orderStatusEnum(item),
              };
            },
          );

          formatedPayload.sort(
            (a, b) =>
              new Date(a.sortDate).getTime() - new Date(b.sortDate).getTime(),
          );

          if (option && option !== 0) {
            const newData = formatedPayload.filter(
              (item: IOrdersProps) => item.status === option,
            );

            setRows(newData);
          } else {
            setRows(formatedPayload);
          }
        }
      } catch (err) {
        console.log(err.message);

        addToast({
          type: 'error',
          title: 'Erro na Request',
          description: 'Entrar em contato com o suporte',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [store],
  );

  const handleFilter = (option: number) => {
    navigate({
      pathname: '/orders',
      search: `?status=${option}`,
    });
  };

  useEffect(() => {
    const queryParamsBase = search.replace('?', '');
    const queryParams: any = {};
    queryParamsBase.split('&').forEach(param => {
      const paramValue = param.split('=');
      queryParams[paramValue[0]] = paramValue[1];
    });
    getOrders(Number(queryParams.status));
  }, [store, search]);

  const actionTextSize = {
    fontSize: '12px',
  };

  return (
    <BaseLayoutPage
      title="Lista de Coletas"
      toolbar={<DashboardToolbar showFilter handleFilter={handleFilter} />}
    >
      {isLoading ? (
        <Box width="100%">
          <LinearProgress color="primary" />
        </Box>
      ) : rows.length <= 0 ? (
        <Box padding={2}>
          <Typography variant="subtitle2">
            Sem pedidos de separação para esta loja
          </Typography>
        </Box>
      ) : (
        <Box>
          {rows.map((row: IOrdersProps) => (
            <Box key={Math.random()} margin={1}>
              <Card
                style={{
                  background: row.show_red_alert ? '#f7b7b7' : '#fff',
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding={3}
                >
                  <Box display="flex" alignItems="center">
                    <Avatar
                      alt={row.customer_name}
                      src={row.customer_info.image}
                    />

                    <Box marginLeft={2}>
                      <Typography variant="subtitle1">Pedido de</Typography>
                      <Typography variant="subtitle2">
                        {row.customer_info.name}
                      </Typography>
                    </Box>
                  </Box>

                  <Box marginRight={2} marginLeft={2} flex={1}>
                    <Typography variant="subtitle1" width="100%">
                      Pedido
                    </Typography>
                    <Typography variant="subtitle1">
                      #{row.external_id}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <IconButton
                      size="small"
                      onClick={() => navigate(`/orders/details/${row.id}`)}
                    >
                      <Icon>add_shopping_cart</Icon>
                    </IconButton>
                  </Box>
                </Box>

                <Box paddingLeft={3} display="flex" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    style={{ marginLeft: '8px' }}
                  >
                    {row.statusDesc}
                  </Typography>
                </Box>

                <Divider />

                <Box
                  paddingLeft={3}
                  margin="16px 0"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                  >
                    {row.delivery_type === 'DELIVERY'
                      ? 'ENTREGA AGENDADA'
                      : row.delivery_type === 'PICKUP'
                      ? 'RETIRADA NA LOJA'
                      : 'ENTREGA COMUM'}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{ margin: '0 8px' }}
                  >
                    {row.deliveryDate}
                  </Typography>
                </Box>

                <Divider />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding={2}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    padding={1}
                    borderRadius={1}
                    border={1}
                    width="100%"
                  >
                    <Icon>receipt_long</Icon>
                    <Typography
                      variant="subtitle1"
                      marginLeft={2}
                      style={actionTextSize}
                    >
                      Itens
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      marginLeft={2}
                      style={actionTextSize}
                    >
                      {row.total_items}
                    </Typography>
                  </Box>

                  <div style={{ margin: '0 4px' }} />

                  <Box
                    display="flex"
                    alignItems="center"
                    padding={1}
                    borderRadius={1}
                    border={1}
                    borderColor="primary"
                    width="100%"
                  >
                    <Icon>access_time</Icon>
                    <Typography
                      variant="subtitle1"
                      marginLeft={2}
                      style={actionTextSize}
                    >
                      Tempo
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      marginLeft={2}
                      style={actionTextSize}
                    >
                      {row.time_estimated || '00'} min
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          ))}
        </Box>
      )}
    </BaseLayoutPage>
  );
};
