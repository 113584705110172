import { useEffect } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import { useDrawerContext } from '../shared/contexts/DrawerContext';
import { MenuSideBar } from '../shared/components';
import {
  Dashboard,
  ForgotPassword,
  SignIn,
  Orders,
  Products,
  OrdersDetails,
  Picking,
} from '../pages';

import { PrivateRoutes } from './PrivateRoutes';

export const AppRoutes: React.FC = () => {
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {
    setDrawerOptions([
      {
        label: 'Página Inicial',
        icon: 'home',
        path: '/home',
      },
      {
        label: 'Pedidos',
        icon: 'list_all',
        path: '/orders',
      },
    ]);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<SignIn />} />

      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route element={<PrivateRoutes />}>
        <Route
          path="/home"
          element={
            <MenuSideBar>
              <Dashboard />
            </MenuSideBar>
          }
        />

        <Route
          path="/products"
          element={
            <MenuSideBar>
              <Products />
            </MenuSideBar>
          }
        />

        <Route
          path="/orders"
          element={
            <MenuSideBar>
              <Orders />
            </MenuSideBar>
          }
        />

        <Route
          path="/orders/details/:id"
          element={
            <MenuSideBar>
              <OrdersDetails />
            </MenuSideBar>
          }
        />
        <Route
          path="/details/picking"
          element={
            <MenuSideBar>
              <Picking />
            </MenuSideBar>
          }
        />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
