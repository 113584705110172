import { api } from '../axios-config';

import { useToast } from '../../../contexts/ToastContext';

import IAuthLoginDto from '../../../Dtos/IAuthLogin';

interface ILoginProps {
  email: string;
  password: string;
}

const auth = async (loginData: ILoginProps): Promise<IAuthLoginDto | Error> => {
  const { addToast } = useToast();

  try {
    const { email, password } = loginData;

    const response = await api.post('/login', {
      email,
      password,
    });

    const { data } = response.data;
    const { access_token, expires_in, payload } = data;

    if (data)
      return {
        token: {
          accessToken: access_token,
          expiresIn: expires_in,
        },
        user: payload,
      };

    addToast({
      type: 'error',
      title: 'Erro ao logar',
      description: 'Verifique seus dados',
    });

    return new Error('Erro no login');
  } catch (error) {
    addToast({
      type: 'error',
      title: 'Erro ao logar',
      description: 'Verifique seus dados',
    });

    return new Error((error as { message: string }).message || 'Erro ao logar');
  }
};

export const authService = { auth };
