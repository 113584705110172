/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { NumericFormat } from 'react-number-format';

import {
  Box,
  Typography,
  Card,
  Button,
  Modal,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputAdornment,
  Paper,
} from '@mui/material';

import { Form } from '@unform/web';

import { BaseLayoutPage } from '../../../shared/layouts';

import { useStore } from '../../../shared/contexts/StoreContext';
import { useToast } from '../../../shared/contexts/ToastContext';

import { DetailsToolbar } from '../../../shared/components';

import { VTextField } from '../../../shared/components/forms/index';

import {
  updateProductQuantity,
  getHallwayByStore,
  updateProductLocation,
} from '../../../api/pickingAppApi';

import { IOrdersDto, IProducts } from '../../../shared/Dtos/IOrdersDto';
import { ILocationsDto } from '../../../shared/Dtos/ILocationsDto';

const styleBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '220px',
  bgcolor: 'background.paper',
  border: '2px solid #ffffff',
  borderRadius: '4px',
  boxShadow: 24,
  p: 3,
};

interface IPicking {
  order: IOrdersDto;
  product: IProducts;
}

interface ISelectProps {
  label: string;
  value: number;
}

export const Picking: React.FC = () => {
  const location = useLocation();
  const { store } = useStore();
  const { addToast } = useToast();

  const { order, product } = location.state as IPicking;

  console.log('product', product);

  const [open, setOpen] = useState(false);
  const [look, setLook] = useState(false);
  const [quantity, setQuantity] = useState(product.quantity);

  const [isLoading, setIsLoading] = useState(false);

  const [locations, setLocations] = useState<ILocationsDto[]>([]);

  const [hallways, setHallways] = useState<ISelectProps[]>([]);
  const [hallway, setHallway] = useState(
    product.location_hallway_id?.toString() || '',
  );

  const [shelfs, setShelfs] = useState<ISelectProps[]>([]);
  const [shelf, setShelf] = useState(
    product.location_shelf_id?.toString() || '',
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNotLook = () => setLook(false);

  const navigate = useNavigate();

  const getHallwaysShelfs = useCallback(async () => {
    if (store) {
      if (!product.location_hallway_id) {
        const { data } = await getHallwayByStore(store);

        setLocations(data.locations);

        const locationsFormated = data.locations.map((item: ILocationsDto) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        setHallways(locationsFormated);
      }
    }
  }, []);

  const productImage = product?.images?.find((it: any) => it.type === 'small')
    ?.url
    ? product?.images?.find((it: any) => it.type === 'small')?.url
    : `https://ui-avatars.com/api/?background=fff&color=333&name=${product.product_name}`;

  const handleBackButton = useCallback(() => {
    navigate(`/orders/details/${order.id}`);
  }, []);

  const handleNotFound = async () => {
    if (store) {
      setIsLoading(true);

      await updateProductQuantity({
        orderId: order.id,
        itemId: product.id,
        collected: product.collected,
        notFound: true,
      });

      await updateProductLocation({
        productId: product.product_id.toString(),
        storeId: store,
        locationHallwayId: Number(hallway),
        locationShelfId: Number(shelf),
      });

      setIsLoading(false);
      setLook(false);
    }
  };

  interface IData {
    unitQuantity?: string | null;
  }

  const handleSaveQuantityOrder = async (data: IData) => {
    if (!data.unitQuantity) {
      addToast({
        type: 'error',
        title: 'Sem quantidade',
        description: 'Para continuar digite uma quantidade !',
      });
      return;
    }

    product.collected = Number(data.unitQuantity.replace(/,/g, '.'));
    if (product.collected >= product.quantity * 3) {
      const confirmation = window.confirm(
        'Quantidade está 3x maior do que a solicitada, confirma ?',
      );
      if (!confirmation) return;
    }

    if (
      product.quantity !== product.collected &&
      product.isProductWeighable &&
      product.unit?.toLowerCase() === 'un'
    ) {
      setLook(true);
    } else {
      if (store) {
        setIsLoading(true);

        await updateProductQuantity({
          orderId: order.id,
          itemId: product.id,
          collected: product.collected,
        });

        await updateProductLocation({
          productId: product.product_id.toString(),
          storeId: store,
          locationHallwayId: Number(hallway),
          locationShelfId: Number(shelf),
        });

        setIsLoading(false);
      }
    }
    setOpen(false);
  };

  const handleChangeSelectShelf = (event: SelectChangeEvent) => {
    setShelf(event.target.value);
  };

  const handleChangeSelectHallways = (event: SelectChangeEvent) => {
    setHallway(event.target.value);

    const findShelfs = locations.find(
      item => item.id === Number(event.target.value),
    );

    if (!findShelfs) {
      setShelfs([]);
      setShelf('');
      return;
    }

    if (findShelfs) {
      const formatedShelfs = findShelfs.shelf.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setShelfs(formatedShelfs);
    }
  };

  useEffect(() => {
    getHallwaysShelfs();
  }, []);

  return (
    <BaseLayoutPage
      title="Coleta"
      toolbar={
        <DetailsToolbar
          showNewButton={false}
          showDeleteButton={false}
          showSaveButton={false}
          onClickBack={handleBackButton}
        />
      }
    >
      <Box margin={2}>
        <Box>
          <Typography variant="caption" style={{ opacity: 0.6 }}>
            {product.location_hallway_name}
          </Typography>

          <Typography variant="body1">{product.product_name}</Typography>

          <Typography variant="caption" style={{ opacity: 0.6 }}>
            Cód. {product.product_id}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" marginTop={2} marginBottom={2}>
          <img
            src={productImage}
            alt="Foto"
            width="170px"
            height="170px"
            style={{ borderRadius: '6px' }}
          />
          <Box margin={3} textAlign="center">
            <Box display="flex" justifyContent="space-evenly">
              <Typography variant="subtitle1">Un. Medida: </Typography>
              <Typography variant="subtitle1" color="secondary">
                {!product.isProductWeighable
                  ? product.unit?.toUpperCase()
                  : product.productUnitMeasure?.toUpperCase()}
              </Typography>
            </Box>

            <Typography variant="subtitle2">Qtde. Solicitada</Typography>

            <Typography variant="h3">{product.quantity}x</Typography>
            <Typography
              variant="h6"
              style={{ marginTop: '16px' }}
              color="secondary"
            >
              Qtde. coletada {product.collected}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Card style={{ borderRadius: '6px', padding: '8px' }}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Typography variant="caption">Corredor</Typography>

              {product.location_hallway_name ? (
                <Typography
                  variant="subtitle2"
                  style={{
                    background: '#ededed',
                    width: '100%',
                    textAlign: 'center',
                    padding: '4px',
                    color: '#2516fe',
                  }}
                >
                  {product.location_hallway_name}
                </Typography>
              ) : (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label-hallway">
                    Selecione o corredor
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-hallway"
                    id="demo-simple-select-hallway"
                    value={hallway}
                    label="Selecione o corredor"
                    onChange={handleChangeSelectHallways}
                  >
                    {hallways.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              marginTop={2}
              flexDirection="column"
            >
              <Typography variant="caption">Gondola</Typography>
              {product.location_shelf_name ? (
                <Typography
                  variant="subtitle2"
                  style={{
                    background: '#ededed',
                    width: '100%',
                    textAlign: 'center',
                    padding: '4px',
                    color: '#2516fe',
                  }}
                >
                  {product.location_shelf_name}
                </Typography>
              ) : (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label-shelf">
                    Selecione a gondola
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-shelf"
                    id="demo-simple-select"
                    value={shelf}
                    label="Selecione a gondola"
                    onChange={handleChangeSelectShelf}
                    disabled={!shelfs.length}
                  >
                    {shelfs.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Card>
        </Box>
        {product.obs && (
          <Box component={Paper} marginTop={2} padding={2}>
            <Typography variant="subtitle1" color="secondary">
              Observações
            </Typography>
            <Typography variant="subtitle1">{product.obs}</Typography>
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Button variant="contained" onClick={handleOpen} fullWidth>
            Coletar
          </Button>

          {/* Modal que controla se não foi
          encontrado a quantidade total */}
          <Modal
            open={look}
            onClose={handleNotLook}
            aria-labelledby="modal-modal-title"
          >
            <Box sx={styleBox}>
              <Typography variant="subtitle2">
                Foram coletados {product.collected}, confirma que o restante não
                foi encontrado?
              </Typography>
              <Box
                padding={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  marginTop={2}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <TextField
                    type="text"
                    value={product.quantity - product.collected}
                    label="saldo"
                    disabled
                    InputProps={{
                      style: { textAlign: 'center' },
                      endAdornment: (
                        <InputAdornment position="end">
                          {product.unit}
                        </InputAdornment>
                      ),
                    }}
                  />

                  {isLoading ? (
                    <CircularProgress
                      variant="indeterminate"
                      color="secondary"
                      style={{ height: '36.5px', marginTop: '16px' }}
                    />
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={handleNotFound}
                      style={{
                        marginTop: '16px',
                      }}
                    >
                      <Typography variant="button" color="white">
                        Confirmar
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Modal>
          {/**
           * Modal que controla os componentes para realizar o picking
           */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
          >
            <Box sx={styleBox}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Form onSubmit={handleSaveQuantityOrder}>
                  <NumericFormat
                    decimalSeparator=","
                    customInput={VTextField}
                    name="unitQuantity"
                    label="Digite a quantidade"
                    placeholder={product.productUnitMeasure ? '0,00' : '0'}
                    decimalScale={product.productUnitMeasure ? 3 : 0}
                    onChange={e => setQuantity(Number(e.target.value))}
                    value={quantity}
                    InputProps={{
                      style: { textAlign: 'center' },
                      endAdornment: (
                        <InputAdornment position="end">
                          {product.productUnitMeasure
                            ? product.productUnitMeasure?.toUpperCase()
                            : product.unit?.toUpperCase()}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    color="secondary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    style={{ marginTop: '16px' }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        variant="indeterminate"
                        color="secondary"
                        style={{
                          width: '24px',
                          height: '24px',
                          color: '#fff',
                        }}
                      />
                    ) : (
                      <Typography variant="button" color="white">
                        Salvar
                      </Typography>
                    )}
                  </Button>
                </Form>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </BaseLayoutPage>
  );
};
